.bg_header{
    background-color: #2A2761;
}
.bg_header_sec {
    background-color: #0d094acc;
    z-index: 9;
    backdrop-filter: blur(6px);
}
.bg_header_sec_mode {
    background-color: #000000a6;
    z-index: 9;
    backdrop-filter: blur(4px);
}
.myHeader { 
    margin-top: 0px;
     /* background: yellow !important; */
      transition: .5s;
    }
.mycol {
     margin-top: -110px; 
     /* background: green !important; */
      transition: .5s;
}
.font_size{
    font-size: 14px;
}
@media (max-width: 600px) and (max-width: 768px){
    a{
        font-size: 12px !important;
    }
}