.bg_footer{
    background-color: #5b47ff0d;
}
.bg_footers{
    background-color: #1a1416;
}
.get_touchs{
    -webkit-text-stroke: 1.5px;
}
.font_small{
    font-size: 14px;
}
.text-justify{
    text-align: justify;
}
.bg_shadow {
    background: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 3px #bdb5b54a;
}
@media (max-width: 600px) and (max-width: 768px)
{.get_touchs {
    -webkit-text-stroke: 1px;
}
.text-justify{
    text-align: center;
}
.text_center_phns{
    text-align: left;
}
}