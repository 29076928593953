.custom_ds_input>input{
    width: 49%;
    background-color: #E8E8E8;
    border: 0px;
    margin: 3px;
    padding: 10px;
    border-radius: 3px;
}
.custom_ds_input>input:focus{
    outline: 0;
}
.address_textarea>textarea{
    width: 99%;
    height: 150px;
    background-color: #E8E8E8;
    border: 0px;
    margin: 3px;
    padding: 10px;
    border-radius: 3px;
    resize: none;
}
.address_textareas>textarea {
    width: 99%;
    height: 150px;
    background-color: #4c4c4f;
    border: 1px solid white;
    color: white;
    margin: 3px;
    padding: 10px;
    border-radius: 3px;
    resize: none;
}
.address_textarea>textarea:focus{
    outline: 0;
}
.get_touch{
    -webkit-text-stroke: 2px;
}
.btn_cs{
    background: #0d094a !important;
    color: white !important;
}
.custom_ds_inputs>input {
    width: 49%;
    background-color: #4c4c4f;
    border: 1px solid white;
    margin: 3px;
    color: white;
    padding: 10px;
    border-radius: 3px;
}
@media (max-width: 600px) and (max-width: 768px){
.custom_ds_input>input{
    width: 99%;
}
.get_touch {
    -webkit-text-stroke: 1px;
    text-align: center;
}
.text_center_phn{
text-align: center;
}
.text_center_phn>img{
    width: 100% !important;
}
.justify_text{
    text-align: justify;
}
}