:root{
    --text-primary-cs : #2A2761;
    --text-primary-dark : #0faae9;
}
.bg_fr_sec{
    background-image: url('../../../images/1st_section_bg/aotrek.png');
    background-size: 60%;
    background-repeat: no-repeat;
    /* background-position: center; */
}
.font_aotrek{
    color: var(--text-primary-cs);
    font-size: 50px;
    -webkit-text-stroke: 3px var(--text-primary-cs);
}
.font_aot{
 color: var(--text-primary-cs);
    font-size: 50px;
    -webkit-text-stroke: 3px var(--text-primary-dark);
}