.cursor_pointer>p{
    cursor: pointer;
}
.text_border{
    border-bottom: 1px solid white;
}
.custom_positions {
    transform: rotate(-90deg);
    right: -175px;
    top: 35%;
}
.custom_positions>h1 {
    font-size: 100px;
    color: #4eadd4;
    -webkit-text-stroke: 3px #4eadd4;
}
.custom_positions_dark>h1 {
    font-size: 100px;
    color: #6f6f70;
    -webkit-text-stroke: 3px #6e6e77b3;
}
.bg_color_about{
    background: linear-gradient(284deg, #0EA2DE -19.22%,#07516F 118.87%);
}
.bg_about{
    background: linear-gradient(284deg,#000000 -19.22%,#45454d 118.87%);
}
.font_size{
    font-size: 14px;
}
.cus_pb{
    padding-bottom: 70px;
}
@media (max-width: 600px) and (max-width: 768px){
footer p {
    font-size: 14px;
}}