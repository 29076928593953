.bg_brand{
    background-image: url('../../../images/6th_section_bg/aotrek_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg_brands{
    background:linear-gradient(black,gray) !important
}
.w_50{
    width: 50%;
}
@media (max-width: 600px) and (max-width: 768px){
.w_100{
    width: 100%;
}
}