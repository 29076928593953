.custom_position {
    transform: rotate(270deg);
    left: -103px;
    top: 38%;
}
.custom_position>h1{
    font-size: 85px;
    color: #4eadd4;
    -webkit-text-stroke: 3px #4eadd4;
}
.bg_gradient {
    background: linear-gradient(284deg,#07516F -19.22%,#0EA2DE 118.87%);
    border-radius: 10px;
    overflow: hidden;
}
.bg_gradi{
    background: linear-gradient(284deg,#000000 -19.22%,#45454d 118.87%);
    border-radius: 10px;
    overflow: hidden;
}
.custom_position_dark>h1 {
    font-size: 85px;
    color: #ababb778;
    -webkit-text-stroke: 3px #6e6e77;
}
.stork_text{
    -webkit-text-stroke: 1.5px ;
}