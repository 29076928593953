::-webkit-scrollbar {
  width: .1px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #2a276100; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2a276100; 
}
/* .styles_scroll-to-top__2A70v {
    bottom: 100px !important;
}
.fb_dialog_content>iframe{
    right: 20px;
    top: auto;
    bottom: 90px;
} */
.bg_main_dark{
  background-color: #4c4c4f;
}
.form-check-input:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 109, 253, 0) !important;
}
.form-switch .form-check-input:focus {
    background-image: url("./img/moon.png") !important;
}
.form-switch .form-check-input {
    background-image: url("./img/moon.png") !important;
  }
  .form-check-input:checked {
    background-color: #d0f2ff !important;
    border-color: #29265f !important;
}